export function trimToSlug(text: string) {
    return text
        .replaceAll(' ', '-')
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll('ä', 'a')
        .replaceAll('ö', 'o')
        .replaceAll('å', 'a')
        .toLowerCase()
}

export function addParamToCurrentUrl(key: string, value: string) {
    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    queryParams.delete(key);
    queryParams.append(key, value);
    const newUrl = currentUrl.origin + currentUrl.pathname + '?' + queryParams;
    window.history.pushState({}, '', newUrl);
}

export function deletePramsFromCurrentURL() {
    const currentUrl = new URL(window.location.href);
    const newUrl = currentUrl.origin + currentUrl.pathname;
    window.history.pushState({}, '', newUrl);
}