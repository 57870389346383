import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Iframe from './components/Iframe';
import FrontPage from './pages/FrontPage';
import ShortnerPage from './pages/ShortnerPage';
import { Button, Typography } from '@mui/material';
import { Home, Fireplace, AttachMoney, Groups, Article, Park, Pets, HistoryEdu, AddLink, Help } from '@mui/icons-material'

export enum LinkType {
    IFRAME,
    EXTERNAL,
    INTERNAL
}

export interface Link {
    title: string;
    icon: React.ReactNode;
    slug: string;
    src?: string;
    component?: any;
    type: LinkType;
    customContent?: React.ReactNode;
}

export const links: Link[] = [
    {
        title: "Etusivu",
        icon: <Home />,
        slug: "/",
        component: FrontPage,
        type: LinkType.INTERNAL
    },
    {
        title: "Retki-ilmoitus",
        icon: <Park />,
        slug: "/retki-ilmoitus",
        src: "https://docs.google.com/forms/d/e/1FAIpQLSeku_U9beSHHhnpg1R_UphzvjSDzTdLuqwbCNS0tO--v9e8zw/viewform?embedded=true",
        type: LinkType.IFRAME
    },
    {
        title: "Kämpän varauslista",
        icon: <Fireplace />,
        slug: "/kampan-varauslista",
        src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vTm2Sygx9ERO1HfPWA35B5aTskbFqfpGoBClrddOBzcP7J8yEN0jWyu1D9cWlvXthqpcJ0Th-fXbkRc/pubhtml?widget=true&amp;headers=false",
        type: LinkType.IFRAME,
        customContent: (
            <div style={{ margin: 20 }}>
                <Typography variant={'body1'} sx={{ color: 'text.primary' }}>Sheets on upotuksessa vain luku tilassa</Typography>
                <Button
                    onClick={() => window.open("https://docs.google.com/spreadsheets/d/10k5WSQQ7uKZE2WAWeNOICAo72rq15JwvLztjAi5ABIE")}>
                    {'Muokkaa kalenteria drivessä ->'}
                </Button>
            </div>)
    },
    {
        title: "Kululasku",
        icon: <AttachMoney />,
        slug: "/kululasku",
        src: "https://varpo.kululaskut.fi",
        type: LinkType.IFRAME
    },
    {
        title: "Kokousseuranta",
        icon: <Groups />,
        slug: "/kokousseuranta",
        src: "https://docs.google.com/forms/d/e/1FAIpQLSdHzVokZTjRiuH38IhIV-PjuzhdcuSZ27F1c1Ny6TsuzDvCQw/viewform?embedded=true",
        type: LinkType.IFRAME
    },
    {
        title: "Kokoussuunnitelma",
        icon: <Article />,
        slug: "/kokoussuunnitelma",
        src: "https://docs.google.com/forms/d/e/1FAIpQLScrAO94FutyT3_MHm6jrKApc31x0NN7rMHzKbsgbJbWHZldyg/viewform?embedded=true",
        type: LinkType.IFRAME
    },
    {
        title: "Jänkäsusihakemus",
        icon: <Pets />,
        slug: "/jankasusi",
        src: "https://docs.google.com/forms/d/e/1FAIpQLSf-qQrNDLDp3JsoKm0Y9gxa6Mzedt00acD7YChJxWaIds4JKg/viewform?embedded=true",
        type: LinkType.IFRAME
    },
    {
        title: "Häväri",
        icon: <HistoryEdu />,
        slug: "/havari",
        src: "https://docs.google.com/forms/d/e/1FAIpQLSd0pT_qkfCxjWt91Wtne40UOI9uL0TbM22Dr2_KghFTxDLwOQ/viewform?embedded=true",
        type: LinkType.IFRAME
    },
    {
        title: "Linkin lyhennin",
        icon: <AddLink />,
        slug: "/linkin-lyhennin",
        component: ShortnerPage,
        type: LinkType.INTERNAL
    },
    {
        title: "Johtajiston ohjeet",
        icon: <Help />,
        slug: '/ohjeet',
        src: 'https://ohjeet.varpo.fi',
        type: LinkType.EXTERNAL
    }
]

function Routes() {
    return (
        <Switch>
            {links.map((site) => {
                if (site.type === LinkType.IFRAME && site.src) {
                    return (
                        <Route key={site.slug} path={site.slug} exact={true} >
                            <Iframe src={site.src} customContent={site.customContent} />
                        </Route>
                    )
                }
                if (site.type === LinkType.INTERNAL && site.component) {
                    return (
                        <Route key={site.slug} component={site.component} path={site.slug} exact={true} />
                    )
                }
                return ''
            }
            )}
        </Switch>
    );
}

export default withRouter(Routes);
