import * as React from 'react'
import { UserContextProvider } from './userContext';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { createTheme, ThemeProvider } from '@mui/material';
import { red } from '@mui/material/colors';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from '@mui/material/GlobalStyles';


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: red[500] },
    background: {
      default: '#08071a',
      paper: '#08071a'
    }
  }
});

const inputGlobalStyles = <GlobalStyles styles={{
  html: { scrollBehavior: 'smooth' },
  body: { fontFamily: 'Roboto', margin: '0px' }
}} />;

function App() {

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {inputGlobalStyles}
        <SnackbarProvider maxSnack={3}>
          <UserContextProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </UserContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
