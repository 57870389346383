import * as React from 'react'
import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, CircularProgress, Divider, IconButton, InputAdornment, Link, List, ListItem, ListItemText, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface ShortLink {
    id: string,
    URL: string
}

function Shortner() {

    const [links, setLinks] = useState<ShortLink[]>()
    const [from, setFrom] = useState<string>()
    const [to, setTo] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreating, setIsCreating] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const { enqueueSnackbar } = useSnackbar();

    function getLinks() {
        setIsLoading(true);
        const listLinks = firebase.functions().httpsCallable("listLinks");
        listLinks().then((res: any) => {
            setLinks(JSON.parse(res.data))
        }).catch((res: any) => {
            enqueueSnackbar(`Tapahtui virhe ${res?.data?.statusCode}`, { variant: 'error' });
        }).finally(() => setIsLoading(false))
    }

    function addLink() {

        if (!from) {
            return enqueueSnackbar('Lyhennettävä linkki puuttuu', { variant: 'error' });
        }
        if (!to) {
            return enqueueSnackbar('Lyhyt linkki puuttuu', { variant: 'error' });
        }
        if (!isValidURL(from)) {
            return enqueueSnackbar('Lyhennettävä linkki on virheellinen. Linkin pitää sisältää esimerkiksi http:// tai https:// etulitteen', { variant: 'error' });
        }
        if (!isValideSlug(to)) {
            return enqueueSnackbar('Lyhyt linkki on virheellinen. Lyhennys voi sisältää vain kirjaimia ja numeroita sekä väliviivoja - (ei siis .,:/ tai ääkkösiä)', { variant: 'error' });
        }
        setIsCreating(true)

        const createLink = firebase.functions().httpsCallable("createLink");
        createLink({ id: to, URL: from })
            .then((res: any) => {
                getLinks()
                enqueueSnackbar(res?.data?.statusCode === 200 ? 'Linkki päivitetty' : 'Linkki luotu', { variant: 'success' });
            }).catch(() => {
                enqueueSnackbar('Linkin luonti epäonnistui. Lyhennys saattaa olla jo käytössä. Yritä vaihtaa lyhyt linkkiä', { variant: 'error' });
            })
            .finally(() => setIsCreating(false))
    }

    function deleteLink(id: string) {
        setIsDeleting(true)
        firebase.functions().httpsCallable("deleteLink")(id).then(() => getLinks()).finally(() => setIsDeleting(false))
    }

    function isValidURL(str: string) {
        return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i.test(str);
    }

    function isValideSlug(slug: string) {
        return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(slug)
    }

    useEffect(() => {
        if (!links && !isLoading) {
            getLinks()
        }
    })

    return (
        <div style={{ padding: 20, maxWidth: '600px', margin: 'auto' }}>
            <TextField
                style={{ margin: '5px', width: '100%' }}
                label="Mistä"
                placeholder={'drive.varpo.fi/ryhmasiretkitiedote'}
                value={from}
                onChange={(e: any) => setFrom(e.target.value)}
            />
            <TextField
                style={{ margin: '5px', width: '100%' }}
                label="Mihin"
                placeholder={"muflonit-retki"}
                InputProps={{
                    startAdornment: <InputAdornment position="start">lyhyt.varpo.fi/</InputAdornment>
                }}
                value={to}
                onChange={(e: any) => setTo(e.target.value)}
            />
            <Button
                variant={'contained'}
                onClick={addLink}
                style={{ margin: '5px' }}
                startIcon={isCreating ? <CircularProgress /> : <SaveIcon />}
            >
                Tallenna
            </Button>
            {isLoading || isDeleting ?
                <Box sx={{ height: 300, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                :

                <List dense={true}>
                    {links?.map(item =>
                        <>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteLink(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    sx={{ color: 'text.primary' }}
                                    primary={
                                        <Box
                                            component="p"
                                            overflow="hidden"
                                            whiteSpace="pre-line"
                                            textOverflow="ellipsis"
                                        >
                                            {`lyhyt.varpo.fi/${item.id}`}
                                        </Box>
                                    }
                                    secondary={
                                        <Link href={item.URL} target={'_blank'}>
                                            <Box
                                                component="p"
                                                overflow="hidden"
                                                whiteSpace="pre-line"
                                                textOverflow="ellipsis"
                                            >{item.URL}</Box>
                                        </Link>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )}
                </List>
            }
        </div >

    )

}
export default Shortner
