import * as React from "react";
import { UserContext } from "../userContext";
import { useContext } from "react";
import Login from "./Login";

interface Props {
  children: React.ReactNode;
}

function PrivateContent(props: Props) {

  const userContext = useContext(UserContext)

  return (
    <>
      { userContext?.user ?
        props.children
        :
        <Login/>
      }
    </>
  )
}

export default PrivateContent;