import * as React from 'react';
import app from "./config";
import { useAuthState } from 'react-firebase-hooks/auth';
import { createContext } from 'react';
import firebase from 'firebase';


const auth = app.auth();

interface Props {
    children: React.ReactNode;
}

interface UserContextInterface {
    user: any;
    login: () => Promise<any>;
    logout: () => void;
}

export const UserContext = createContext<UserContextInterface | undefined | null>(undefined);

export const UserContextProvider = (props: Props) => {
    const [user] = useAuthState(auth);

    async function login() {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            'hd': 'varpo.fi',
            'redirect_uri': window.location
        });
        provider.addScope('email');
        return auth.signInWithPopup(provider)
    }

    function logout() {
        auth.signOut()
    }
    
    return (
        <UserContext.Provider
            value={{
                user,
                login,
                logout
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}