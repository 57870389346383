import * as React from 'react';
import { useContext } from 'react';
import PageLayout from '../components/PageLayout';
import { UserContext } from '../userContext';
import Login from '../components/Login';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Blocks from '../components/Blocks';

function FrontPage() {
    const userContext = useContext(UserContext)

    return (
        <PageLayout isPublic={true}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px', gap: '20px', padding: '10px' }}>
                {userContext?.user ?
                    <>
                        <Blocks page={'frontpage'}/>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {userContext?.user.displayName}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {userContext?.user.email}
                                    {userContext?.user.phoneNumber}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    target='_blank'
                                    href='https://docs.google.com/spreadsheets/d/1EFCWKeeMA6SCiLa9AoDgiHS_X-m3qHN6WEGZ4ItwN9Y/edit?usp=sharing'
                                >
                                    Siirry antamaan kehitysideoita
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    onClick={userContext?.logout}
                                >
                                    Kirjaudu ulos
                                </Button>
                            </CardActions>
                        </Card>
                    </>
                    :
                    <Login />
                }
            </Box>
        </PageLayout>
    )
}

export default FrontPage;