//1. import the dependencies
// import * as firebase from "firebase";
import firebase from 'firebase';
import "firebase/auth";

//2. Initialize app with the config vars
const app = firebase.initializeApp({
    apiKey: "AIzaSyBQrqmqF1mbMGIT6HSw6pGuFrOdJ1_qxQQ",
    authDomain: "mustakansio.varpo.fi",
    databaseURL: "https://musta-kansio.firebaseio.com",
    projectId: "musta-kansio",
    storageBucket: "musta-kansio.appspot.com",
    messagingSenderId: "318943434839"
});

//3. export it for use
export default app;