import * as React from 'react'
import { Link } from 'react-router-dom';
import { links, LinkType } from '../Routes';
import PrivateContent from './PrivateContent';
import { AppBar, Box, Container, Drawer, IconButton, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { gradientText } from './styled/commont';
import { useState } from 'react';

interface Props {
    children: React.ReactNode;
    isPublic?: true
}

function PageLayout(props: Props) {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onMenuChange = () => {
        setIsOpen(!isOpen)
    };


    return (
        <Box sx={{ backgroundColor: '#0e0d18' }}>
            <AppBar position="static" sx={{
                backgroundColor: '#08071a',
                backgroundImage: 'none'
            }}>
                <Container sx={{
                    backgroundColor: '#08071a'
                }}>
                    <Toolbar disableGutters >
                        <Box sx={{ flexGrow: 1 }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={onMenuChange}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor={'left'}
                                open={isOpen}
                                onClose={onMenuChange}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: '#08071a',
                                        backgroundImage: 'linear-gradient(rgb(93 0 151 / 15%), hsl(227deg 89% 11% / 15%))'
                                    }
                                }}
                            >
                                <Box>
                                    <IconButton
                                        size="large"
                                        aria-label="close menu"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={onMenuChange}
                                        color="inherit"
                                        sx={{
                                            margin: '15px 0px 0px 30px'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                {links.map((link) => (
                                    link.type === LinkType.EXTERNAL ?
                                        <a
                                            style={{textDecoration: 'none', color: 'inherit' }}
                                            target='_blank'
                                            rel='noreferrer'
                                            href={link.src}
                                        >
                                            <MenuItem onClick={onMenuChange} >
                                                {link.icon}
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        ...gradientText('#f8f3ff', '#b4a9bd'),
                                                        fontWeight: 900,
                                                        padding: 2
                                                    }}
                                                    textAlign="center"
                                                >
                                                    {link.title}
                                                </Typography>
                                            </MenuItem>
                                        </a>
                                        :
                                        <Link
                                            key={link.slug}
                                            to={link.slug}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <MenuItem onClick={onMenuChange} >
                                                {link.icon}
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        ...gradientText('#f8f3ff', '#b4a9bd'),
                                                        fontWeight: 900,
                                                        padding: 2
                                                    }}
                                                    textAlign="center"
                                                >
                                                    {link.title}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                ))}
                            </Drawer>
                        </Box>
                        <Typography
                            variant="h4"
                            noWrap
                            component="div"
                            sx={{
                                ...gradientText('#fe6b6b', '#ff2525'),
                                fontFamily: "'Raleway', sans-serif",
                                flexGrow: 1
                            }}
                        >
                            Mustakansio
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <div style={{ minHeight: "100vh" }}>
                {props.isPublic ?
                    props.children
                    :
                    <PrivateContent>
                        {props.children}
                    </PrivateContent>
                }
            </div>
        </Box >
    )
}
export default PageLayout