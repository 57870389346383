import * as React from "react";
import PageLayout from "./PageLayout";
import PrivateContent from "./PrivateContent";
import { Alert, Button, Fab } from "@mui/material";

interface Props {
  src: string;
  customContent?: React.ReactNode;
}

function IframePage({ src, customContent }: Props) {
  return (
    <PageLayout>
      <PrivateContent>
        {customContent}
        <iframe
          title={src}
          src={src}
          style={{ minHeight: "92vh" }}
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Ladataan...
        </iframe>
        <Fab
          color="primary"
          variant="extended"
          onClick={() => window.open(src)}
          style={{ position: "absolute", bottom: 10, right: 10 }}
        >
          Eikö upotus näy?
        </Fab>
      </PrivateContent>
    </PageLayout>
  );
}
export default IframePage;
