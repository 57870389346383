import * as React from 'react';
import { Card, CardHeader, CardContent, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
    numberOfCards: number
}

export default function LoadingCards({ numberOfCards }: Props) {
    const times = [...Array(numberOfCards)] as string[]
    return (
        <>
            {times.map((e, i) =>
                <Card key={'s'+i} sx={{ width: '100%', maxWidth: 600, height: 'fit-content', backgroundColor: '#08071a' }}>
                    <CardHeader
                        title={
                            <Box>
                                <Skeleton />
                            </Box>
                        }
                    />
                    <CardContent
                        sx={{
                            margin: 'auto',
                            padding: '20px',
                        }}>
                        <Skeleton />
                    </CardContent>
                </Card>
            )
            }
        </>
    )
}
