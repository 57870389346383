import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { Block } from './Blocks';
import { gradientText } from './styled/commont';
import { addParamToCurrentUrl, deletePramsFromCurrentURL, trimToSlug } from '../utils';

export default function ExpandableCard({ title, contentSummary, media, content }: Block) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const queryParams = new URLSearchParams(window.location.search);
    const isOpen = queryParams.get('open');

    function handleExpandClick() {
        const newState = !expanded
        setExpanded(newState);
        if (newState) {
            addParamToCurrentUrl('open', trimToSlug(title))
        } else {
            deletePramsFromCurrentURL()
        }
    };

    useEffect(function checkIfDeepLinkToModal() {
        if (isOpen === trimToSlug(title)) {
            setExpanded(true)
        }
    }, [isOpen, title])

    return (
        <Card
            id={trimToSlug(title)}
            key={title}
            sx={{
                width: '100%',
                maxWidth: 600,
                minWidth: 350,
                height: 'fit-content',
            }}>
            <CardHeader
                title={
                    <Box
                        sx={{
                            ...gradientText('#726bfe', '#ff3425'),
                            fontSize: '1.25em',
                        }}>
                        <Link href={`#${trimToSlug(title)}`}>
                            {title}
                        </Link>
                    </Box>
                }
            />
            {media &&
                <CardMedia
                    component="img"
                    image={`https:${media.fields?.file?.url}`}
                    alt={media.fields?.title || ''}
                />
            }
            {content &&
                <>
                    <CardContent
                        sx={{
                            a: { color: 'primary.main' },
                            margin: 'auto',
                            color: 'text.primary',
                            padding: '20px',
                            overflow: 'hidden',
                            wordBreak: 'break-word'

                        }}>
                        {contentSummary && <ReactMarkdown children={contentSummary} />}
                    </CardContent>
                    <CardActions disableSpacing>
                        <Typography></Typography>
                        <Button
                            onClick={handleExpandClick}
                            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                            Näytä lisää
                        </Button>
                    </CardActions>
                    <Dialog
                        open={expanded}
                        onClose={handleExpandClick}
                        scroll={'paper'}
                        fullScreen={fullScreen}
                        sx={{
                            a: { color: 'primary.main' },
                            maxWidth: '600px',
                            margin: 'auto',
                            color: 'text.primary',
                            padding: '20px',
                            overflow: 'hidden',
                            wordBreak: 'break-word',
                        }}
                    >
                        <DialogTitle id={title + 'dialog'} sx={{
                            ...gradientText('#726bfe', '#ff3425'),
                            fontSize: '1.25em',
                        }}>{title}
                            <IconButton
                                aria-label="close"
                                onClick={handleExpandClick}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <ReactMarkdown children={content} />
                        </DialogContent>
                    </Dialog>
                </>
            }
        </Card>
    )
}
