import * as React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import ExpandableCard from './ExpandableCard';
import LoadingCards from './LoadingCards';
import { useLocation } from 'react-router-dom';

export interface Block {
    title: string;
    contentSummary?: string;
    media?: any;
    content?: string;
}

interface Props {
    page: string;
    allowSearch?: boolean
}

export default function Blocks({ page, allowSearch }: Props) {

    const [blocks, setBlocks] = useState<Block[]>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [options, setOptions] = useState<string[]>([]);

    const location = useLocation();

    useEffect(function getLinks() {
        setIsLoading(true);
        const listLinks = firebase.functions().httpsCallable("getPages")
        listLinks({ contentType: 'page' })
            .then((res: any) => {
                const rawBlocks = res.data
                    .find(({ fields }: any) => fields.name === page)
                const fields = rawBlocks?.fields?.blocks?.map(({ fields }: any) => fields) as Block[]
                setBlocks(fields)
                setOptions(blocksToSearchOptions(fields))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [page])

    useEffect(function scrollToHref() {
        if (location.hash && blocks && !isLoading) {
            const section = window.document.querySelector(location.hash);
            section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location.hash, blocks, isLoading])

    function blocksToSearchOptions(fields: Block[]): string[] {
        const keyWords = fields?.map(({ title, contentSummary, content }) => {
            let words = [title]
            contentSummary && words.push(contentSummary)
            content && words.push(
                ...content
                    .replaceAll('#', '')
                    .replaceAll('_', '')
                    .replaceAll('"', '')
                    .replaceAll('*', '')
                    .split('\n')
                    .map((s) => s.trim().split('. ')).flat()
                    .filter((s) => s !== ' ')
                    .filter((s) => s !== '')
                    .flat()
            )
            return words
        })
        // @ts-ignore
        return [...new Set(keyWords.flat())] as string[]
    }

    function handleSearchChange(value: string | null) {
        setSearchTerm(value || undefined)
    }

    function isWisibleOnFilter(title: string, contentSummary?: string, content?: string) {
        return searchTerm ? (
            title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            contentSummary?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            content?.toLowerCase().includes(searchTerm.toLowerCase())
        ) : true

    }

    return (
        <>
            {allowSearch &&
                <Autocomplete
                    sx={{ width: '100%', maxWidth: 700 }}
                    freeSolo
                    onChange={(e, value) => handleSearchChange(value)}
                    options={options}
                    renderInput={(params) =>
                        <TextField {...params} label="Hae sisältöä" />
                    }
                />}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(350px, max-content))',
                justifyContent: 'center',
                marginTop: '20px',
                gap: '20px',
                padding: '10px',
                width: '100%'
            }}>
                {!isLoading && blocks ? blocks?.map(({ title, contentSummary, media, content }, i) =>
                    <React.Fragment key={i}>
                        {
                            isWisibleOnFilter(title, contentSummary, content) &&
                            <ExpandableCard
                                key={title + i}
                                title={title}
                                contentSummary={contentSummary}
                                media={media}
                                content={content}
                            />
                        }
                    </React.Fragment>
                ) :
                    isLoading && <LoadingCards numberOfCards={3} />
                }
            </Box >
        </>
    )
}
