import { Button } from '@mui/material';
import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../userContext';

function Login() {

    const userContext = useContext(UserContext)

    return (
        <Button variant='contained' onClick={userContext?.login}>Kirjaudu sisään</Button>
    )
}

export default Login;