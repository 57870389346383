import * as React from 'react'
import PageLayout from '../components/PageLayout'
import Shortner from '../components/Shortner'


function ShortnerPage() {
    return (
        <PageLayout>
            <Shortner/>
        </PageLayout>
    )
}
export default ShortnerPage